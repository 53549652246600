.error {
  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  background-color: #ccc;

  .message {
    background-color: rgb(204, 48, 48);
    border-radius: 6px;
    color: #fff;
    text-align: center;

    padding: 20px;

    h1 {
      font-weight: 500;
      font-size: 18px;
      margin: 0;
    }
  }
}
