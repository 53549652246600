.App {
  justify-content: center;
  display: flex;
  color: #000000;
  font-weight: 400;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgb(255, 255, 255);
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 1) 0%,
    rgba(233, 233, 233, 1) 63%,
    rgba(203, 203, 203, 1) 82%,
    rgba(167, 167, 167, 1) 100%
  );

  input {
    display: flex;
    flex: 1;
    height: 20px;
    resize: none;
    background-color: #ffffff;
    border: 1px solid #ffffff;
    border-radius: 50px;
    outline: none;

    margin: 0 12px 12px 12px;
    padding: 8px 16px;
    font-size: 14px;
    font-weight: 300;
    color: #000000;
    font-weight: 400;
  }

  button {
    cursor: pointer;

    margin-right: 12px;
    height: 40px;
    width: 40px;
    border-radius: 20px;
    background-color: #128c7e;
    border-width: 0;
    text-align: center;
    justify-content: center;
    outline: none;
  }

  .header {
    flex-direction: column;
    display: flex;
    background-color: #128c7e;
    padding: 20px;
    h1 {
      font-size: 30px;
      color: rgba(255, 255, 255, 0.87);
      margin: 0;
    }
    p {
      font-size: 16px;
      font-weight: 500;
      color: rgba(255, 255, 255, 0.87);
      margin: 8px 0 0 0;
    }
  }

  .chatBox {
    flex: 1;
    flex-direction: column;
    display: flex;
    justify-content: flex-end;
  }

  @media screen and (max-width: 768px) {
    // SMALL

    .box {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      flex: 1;
      height: calc(100vh);
      background-color: #ece5dd;
      justify-content: flex-end;
      align-content: flex-end;

      box-shadow: 0px 7px 8px rgba(0, 0, 0, 0.2),
        0px 5px 22px rgba(0, 0, 0, 0.12), 0px 12px 17px rgba(0, 0, 0, 0.14);
    }
  }

  @media screen and (min-width: 768px) {
    // BIG

    .box {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      margin: 48px 0;
      min-height: 450px;
      width: 520px;
      border-radius: 6px;
      background-color: #ece5dd;

      box-shadow: 0px 11px 15px rgba(0, 0, 0, 0.2),
        0px 9px 46px rgba(0, 0, 0, 0.12), 0px 24px 38px rgba(0, 0, 0, 0.14);
    }
  }
}
