.container {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  font-weight: 500;

  overflow: hidden;

  .tailBot {
    align-self: flex-start;
    width: 0;
    height: 0;
    margin: 0 0 0 16px;
    border-top: 12px solid #ffffff;
    border-left: 12px solid transparent;
  }
  .tail {
    align-self: flex-start;
    width: 0;
    height: 0;
    margin: 0 16px 0 0;
    border-top: 12px solid #dcf8c6;
    border-right: 12px solid transparent;
  }

  .messageBot {
    word-break: break-word;
    display: flex;
    margin-bottom: 8px;
    padding: 0 8px 0 8px;
    min-height: 26px;
    max-width: 300px;
    border-radius: 0 6px 6px 6px;
    background-color: #ffffff;
  }

  .message {
    word-break: break-word;
    display: flex;
    margin-bottom: 8px;
    padding: 0 8px 0 8px;
    min-height: 26px;
    max-width: 300px;
    border-radius: 6px 0 6px 6px;
    background-color: #dcf8c6;
  }
}
