@import url(https://fonts.googleapis.com/css?family=Quicksand:300,400,500,600,700&display=swap);
.container{padding:0;margin:0;display:flex;flex-direction:row;align-items:center;font-size:16px;font-weight:500;overflow:hidden}.container .tailBot{align-self:flex-start;width:0;height:0;margin:0 0 0 16px;border-top:12px solid #ffffff;border-left:12px solid transparent}.container .tail{align-self:flex-start;width:0;height:0;margin:0 16px 0 0;border-top:12px solid #dcf8c6;border-right:12px solid transparent}.container .messageBot{word-break:break-word;display:flex;margin-bottom:8px;padding:0 8px 0 8px;min-height:26px;max-width:300px;border-radius:0 6px 6px 6px;background-color:#ffffff}.container .message{word-break:break-word;display:flex;margin-bottom:8px;padding:0 8px 0 8px;min-height:26px;max-width:300px;border-radius:6px 0 6px 6px;background-color:#dcf8c6}

.App{justify-content:center;display:flex;color:#000000;font-weight:400;position:absolute;top:0;bottom:0;left:0;right:0;background:#fff;background:radial-gradient(circle, #fff 0%, #e9e9e9 63%, #cbcbcb 82%, #a7a7a7 100%)}.App input{display:flex;flex:1 1;height:20px;resize:none;background-color:#ffffff;border:1px solid #ffffff;border-radius:50px;outline:none;margin:0 12px 12px 12px;padding:8px 16px;font-size:14px;font-weight:300;color:#000000;font-weight:400}.App button{cursor:pointer;margin-right:12px;height:40px;width:40px;border-radius:20px;background-color:#128c7e;border-width:0;text-align:center;justify-content:center;outline:none}.App .header{flex-direction:column;display:flex;background-color:#128c7e;padding:20px}.App .header h1{font-size:30px;color:rgba(255,255,255,0.87);margin:0}.App .header p{font-size:16px;font-weight:500;color:rgba(255,255,255,0.87);margin:8px 0 0 0}.App .chatBox{flex:1 1;flex-direction:column;display:flex;justify-content:flex-end}@media screen and (max-width: 768px){.App .box{display:flex;flex-direction:column;overflow:hidden;flex:1 1;height:calc(100vh);background-color:#ece5dd;justify-content:flex-end;align-content:flex-end;box-shadow:0px 7px 8px rgba(0,0,0,0.2),0px 5px 22px rgba(0,0,0,0.12),0px 12px 17px rgba(0,0,0,0.14)}}@media screen and (min-width: 768px){.App .box{display:flex;flex-direction:column;overflow:hidden;margin:48px 0;min-height:450px;width:520px;border-radius:6px;background-color:#ece5dd;box-shadow:0px 11px 15px rgba(0,0,0,0.2),0px 9px 46px rgba(0,0,0,0.12),0px 24px 38px rgba(0,0,0,0.14)}}

.error{display:flex;justify-content:center;align-items:center;position:fixed;top:0;bottom:0;left:0;right:0;padding:20px;background-color:#ccc}.error .message{background-color:#cc3030;border-radius:6px;color:#fff;text-align:center;padding:20px}.error .message h1{font-weight:500;font-size:18px;margin:0}

.loader{display:flex;justify-content:center;align-items:center;position:fixed;top:0;bottom:0;left:0;right:0;padding:20px;background-color:#ccc}

body{font-family:'Quicksand', sans-serif;padding:0;margin:0;-webkit-user-select:none;-ms-user-select:none;user-select:none}

